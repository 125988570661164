<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { vaultService } from '../../../services/vault.service';

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,
  numeric,
} from "vuelidate/lib/validators";

import { accountsService } from '../../../services/accounts.service';
import { shopService } from '../../../services/shop.service';


const initForm = { amount: "",currencyType: "", suspenseAccountId:"", agentId: JSON.parse(localStorage.getItem('user')).id, shopId: "", };

export default {
page: {
    title: "Vault",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
},
    data() {

        return {
            supsenseList: [],
            shopList:[],
            fundingSuccess:false,
            fundingFailed: false,
            responseMessage:"",

            currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"rand",
                    currencyName: "RAND"
                },
                {
                    currencyType:"pound",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"euro",
                    currencyName: "EURO"
                }
            ],
            title: "Vault",
            items: [
                {
                    text: "Vault Management",
                    href: "/vault"
                },
                {
                    text: "Vault Funding",
                    active: true
                }
            ],

            form: {
                agentId: JSON.parse(localStorage.getItem("user")).id,
                shopId: null,
                currencyType: null,
                amount: null,
                suspenseAccountId: null
            },

            submitted: false,
            submitform: false,
            submit: false
        };
    },

    validations: {
        form: {
            shopId: {required},
            amount: { required, numeric },
            currencyType: { required },
            suspenseAccountId: {required}
        },
    },

    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    created() {
        this.loadAllAccounts();
        this.loadAllShops();
    },

    methods: { 
        ...notificationMethods,

    async loadAllAccounts() {
        try {
            await accountsService.getAllSuspenseAccounts().then(response=>{
                if(response.responseBody.length>0){
                    this.supsenseList = response.responseBody;
                }
            });
        } catch (error) {
            console.log(error);
        }
    },
    async loadAllShops() {
        try {
            await shopService.getAllShops().then(response=>{
                if(response.responseBody.length>0){
                    this.shopList = response.responseBody;
                }
            });
        } catch (error) {
            console.log(error);
        }
    },
    formSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) { 
                this.submitted = false;
                return;
            } else {
                const {form} = this;
                const vaultForm = {
                        "operation" : "shopCreation",
                        "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                        "requestBody" : form
                };
                
                vaultService.fundVault(vaultForm).then(result=>{
                    if(result.status=="SUCCESS"){
                        this.fundingSuccess = true;
                        this.submitted = false;
                        this.form = Object.assign({}, initForm);
                        this.responseMessage = result.message;
                    } else {
                        this.submitted = false;
                        this.responseMessage = result.message;
                        this.fundingFailed = true;
                    }
                });
            }
        },
    },

};

</script>

<template>
<Layout>
<PageHeader :title="title" :items="items" />
 <div class="card col-md-6" >
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h5>Shop Vault Funding</h5>
                </div>
            </div>
        </div>

        <div class="no-gutters">
            <p>Fund vault with amount equal to physical cash received. This updates the vault balance.</p>
            <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="fundingFailed">
                <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
            </h5>
            <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="fundingSuccess">
                <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
            </h5>
            <form class="needs-registration" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label>Suspense Account</label>
                        <select class="form-control" v-model="form.suspenseAccountId" :class="{ 'is-invalid': submitted && $v.form.suspenseAccountId.$error }">
                            <option v-for="(account, index) in supsenseList" :value="account.accountId" :key="index">{{account.accountName}}</option>
                        </select>
                        <div v-if="submitted && $v.form.suspenseAccountId.$error" class="invalid-feedback">
                            <span v-if="!$v.form.suspenseAccountId.required">This value is required.</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Select Shop</label>
                            <select class="form-control" v-model="form.shopId" :class="{ 'is-invalid': submitted && $v.form.shopId.$error }">
                                <option v-for="(shop, index) in shopList" :value="shop.id" :key="index">{{shop.name}}</option>
                            </select>
                            <div v-if="submitted && $v.form.shopId.$error" class="invalid-feedback">
                                <span v-if="!$v.form.shopId.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="validationCustom02">Amount</label>
                                    <div>
                                        <input
                                            id="validationCustom02"
                                            v-model="form.amount"
                                            type="number"
                                            class="form-control"
                                            placeholder="Enter Amount "
                                            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
                                        />
                                        <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.amount.required">This value is required.</span>
                                            <span v-if="!$v.form.amount.numeric">This value should be a valid number.</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Currency</label>
                                <select class="form-control" v-model="form.currencyType" :class="{ 'is-invalid': submitted && $v.form.currencyType.$error }">
                                    <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                </select>
                                <div v-if="submitted && $v.form.currencyType.$error" class="invalid-feedback">
                                    <span v-if="!$v.form.currencyType.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" v-if="!submitted">
                            <button class="btn btn-primary" type="submit">Fund Shop Vault</button>
                        </div>
                        <div class="col-md-6">
                            <div class="spinner" v-if="submitted">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
                        </div>
                    </div>
                
            </form>
        </div>
    </div>
    </div>
    </Layout>   
</template>